.logo {
  height: 40px;
  margin: 16px;
  text-align: center;
  align-items: center;
  /* background: rgba(255, 255, 255, 0.3); */
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}

.site-page-header {
  background: white;
}

.rightNav {
  text-align: right;
  color: white;
  line-height: 1.2;
  margin: auto auto;
  font-size: 0.8rem;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: unset;
  text-align: center;
}

#root {
  height: 100%;
}

.datePickerStyle {
  :global {
    .ant-picker-now {
      display: none;
    }
  }
}

.row-dragging {
  background: #fafafa;
  border: 1px solid rgb(0, 0, 0);
  z-index: 1500;
}

.row-dragging td {
  padding: 16px;
  z-index: 1500;
}

.row-dragging .drag-visible {
  visibility: visible;
  z-index: 1500;
}

.ant-table-wrapper.task-dashboard {
  tr > th.ant-table-cell {
    color: #0056d8;
    position: relative;
    font-size: 1rem;
    background-color: #f0f0f0;
    &:not(:first-child) {
      &:before {
        content: '';
        position: absolute;
        top: 22.5%;
        left: 0;
        width: 1px;
        height: 55%;
        border-radius: 0.5rem;
        background-color: #0056d8;
        z-index: 1;
      }
    }
    &:not(:last-child) {
      &:after {
        content: '';
        position: absolute;
        top: 22.5%;
        right: 0;
        width: 0.5px;
        border-radius: 0.5rem;
        height: 55%;
        background-color: #0056d8;
        z-index: 1;
      }
    }
  }

  &:nth-child(even) {
    background-color: hsla(206, 92%, 72%, 0.07);
  }
}

@layout-header-background: #76c0f9;