button.header-button[data-sortable='false'] {
  &:hover {
    background-color: $haupColor;
    pointer-events: none;
    color: white;
  }
  .filter-icons {
    display: none;
  }
}

button.header-button[data-sort='null'] {
  .filter-up-down {
    display: none;
  }
}

button.header-button[data-sort='desc'] {
  box-shadow: inset 0 0 10px 5px rgba(115, 14, 14, 0.2);
  background-color: #f5c5c4;
  color: rgb(115, 14, 14);
  &:hover {
    background-color: #f5c5c4;
    color: rgb(115, 14, 14);
    filter: brightness(1.05);
  }
  .filter-up-down,
  .filter-report-main {
    display: none;
  }
  .filter-report-down {
    display: block;
  }
}
button.header-button[data-sort='desc'] {
  box-shadow: inset 0 0 10px 5px rgba(21, 73, 155, 0.2);
  background-color: #dbdbff;
  color: $colorSortUp;
  &:hover {
    background-color: #dbdbff;
    color: $colorSortUp;
    filter: brightness(1.05);
  }
  .filter-up-down,
  .filter-report-main {
    display: none;
  }
  .filter-report-up {
    display: block;
  }
}
