.d-grid {
  display: grid;
}

.d-flex {
  display: flex;
}

$spacing-scale: (
  0: 0,
  1: 0.25rem,
  2: 0.5rem,
  3: 0.75rem,
  4: 1rem,
  5: 1.25rem,
  6: 1.5rem,
  8: 2rem,
  10: 2.5rem,
  12: 3rem,
  16: 4rem,
  20: 5rem,
  24: 6rem,
  32: 8rem,
);

@mixin generate-spacing($prefix, $property) {
  @each $key, $value in $spacing-scale {
    .#{$prefix}-#{$key} {
      #{$property}: $value !important;
    }
  }
}

@include generate-spacing(m, margin);
@include generate-spacing(p, padding);
@include generate-spacing(mx, margin-inline);
@include generate-spacing(my, margin-block);
@include generate-spacing(gap, gap);

.mx-auto {
  margin-inline: auto;
}

.my-auto {
  margin-block: auto;
}

.font-bold {
  font-weight: 700;
}

.text-grey {
  color: grey;
}
