label.title_page {
  font-size: 24px;
}
.box_card_list_cars {
  padding: 20px 0px;
  display: flex;
}
.card_car {
  width: 25%;
  padding: 0px 10px;
  overflow: hidden;
}
img.img_car_card {
  margin-left: auto;
  margin-right: auto;
  /* width: 100%;
  max-height: 210px;
  min-height: 210px;
  height: auto; */
}

/* .box_title_edit {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
} */

.boxcardconten {
  width: 50%;
  height: 470px;
  padding: 0px 10px;
  overflow: hidden;
}
.boxcardconten.boxcardcontennull {
  height: auto;
}
.boxcardconten.height_start {
  height: 460px;
}
.boxcardconten.height_center {
  height: 530px;
}
.boxcardconten.height_end {
  height: 320px;
}
.card_border {
  width: 100%;
  border: 1px solid #707070;
  border-radius: 10px;
  padding: 20px;
  height: 433px;
}
.card_border.height_start {
  height: 420px;
}
.card_border.height_center {
  height: 495px;
}
.card_border.height_end {
  height: 268px;
}

label.title_card_conten {
  font-size: 22px;
  position: relative;
  top: 16px;
  left: 20px;
  background: #ffff;
}
.titel_text_conten,
.des_conten {
  font-size: 16px;
  color: #707070;
}
.des_conten {
  color: #000;
}
.text_rigth {
  text-align: right;
}
.float_rigth {
  float: right;
}
.row_conten {
  /* padding: 10px; */
  padding: 0px 10px;
}
.first_row {
  padding-top: 0px;
}
.address_text {
  height: 79px;
  overflow: hidden;
}
.box_status {
  width: 130px;
  height: 28px;
  border-radius: 20px;
  text-align: center;
  padding: 1px;
}
.text_status {
  font-size: 16px;
  color: #ffff;
}
.box_status.activated {
  background: #71e5b7;
}
.box_status.on_hold {
  background: #f1d58a;
}
.box_status.terminated {
  background: #e57171;
}
.box_status.draft {
  background: #d4d4d4;
}
.box_status.none {
  background: #d4d4d4;
}
.btn {
  font-size: 16px;
  text-align: center;
  border-radius: 20px;
  width: 190px;
  height: auto;
}
.btn_outline_primary {
  border: 1px solid #11428b;
  padding: 5px;
  color: #11428b;
  background: #ffff;
}

.btn_primary {
  background: #11428b;
  border: 1px solid #14499b;
  color: #ffff;
  padding: 5px;
}
.box_btn_card_conten {
  padding: 20px;
}
.btn_vehicle_info {
  border-radius: 10px;
}
.viewcontract {
  width: 20px;
}
.danger {
  background: #e57171;
  color: #ffff;
  padding: 0;
}
.contract_status {
  width: 150px;
  height: 28px;
  font-size: 16px;
  padding: 1px;
  border-radius: 10px;
}

.none {
  background: #d4d4d4;
  border: 1px solid #d4d4d4;
}
label.none {
  border: 0;
  color: #ffff;
}

.draft {
  background: #d4d4d4;
  border: 1px solid #d4d4d4;
}
label.draft {
  border: 0;
  color: #ffff;
}

.activated_status {
  background: #daf1e1;
  border: 1px solid #57b774;
}
label.activated_status {
  border: 0;
  color: #57b774;
}

.on_hold {
  background: #fff6e6;
  border: 1px solid #d36b09;
}
label.on_hold {
  border: 0;
  color: #d36b09;
}

.terminated {
  background: #fedfe6;
  border: 1px solid #fd6585;
}
label.terminated {
  border: 0;
  color: #cc4040;
}

.cancelled {
  background: #ebe0ff;
  border: 1px solid #a97fff;
}
label.cancelled {
  border: 0;
  color: #a97fff;
}

.insurance_status {
  width: 150px;
  height: 28px;
  font-size: 16px;
  padding: 1px;
  border-radius: 10px;
}
.box_premium {
  height: 182px;
}
.rejected {
  border: 1px solid;
  border-color: #d36b09;
  background: #fff6e6;
}
label.rejected {
  border: 0;
  color: #d36b09;
}
.incompleted {
  border: 1px solid;
  border-color: #fd6585;
  background: #fedfe6;
}
label.incompleted {
  border: 0;
  color: #cc4040;
}
.completed {
  border: 1px solid;
  border-color: #57b774;
  background: #daf1e1;
}

.unqualified {
  background: #ebe0ff;
  border: 1px solid #a97fff;
}

.submitted {
  background: #daf1e1;
  border: 1px solid #57b774;
}

label.completed {
  border: 0;
  color: #57b774;
}

label.unqualified {
  border: 0;
  color: #a97fff;
}

label.submitted {
  border: 0;
  color: #57b774;
}

.boxcardconten.insurance_start {
  height: 450px;
}
label.text_checkbox {
  font-size: 16px;
  color: #15499b;
}

.card_border.insurance_start {
  height: 400px;
}
input.input_custom,
select.input_custom {
  padding: 1px 5px;
}

.mr_5 {
  margin-right: 5px;
}
.ml_5 {
  margin-left: 5px;
}
.box_btn {
  width: 100%;
}
a.link {
  font-size: 18px;
}
.link.link_primary {
  color: #11428b;
}
.box_link {
  width: 100%;
  text-align: right;
  margin-right: 20px;
}
a.text_underline {
  text-decoration: underline;
}
.box_des {
  padding: 10px 10px 0px;
}
p.text_des {
  font-size: 16px;
}
.p_20 {
  padding: 20px;
}
.box_document {
  padding-right: 50px;
}
.box_title_document {
  display: flex;
  width: 100%;
}
.box_lable_document {
  width: 80%;
}
.box_check_document {
  text-align: right;
  width: 20%;
}
.title_document {
  font-size: 22px;
}
.box_view_document {
  padding: 20px;
  width: 100%;
}
.view_document {
  width: 569px;
  height: 437px;
  border: 1px solid;
  margin-left: auto;
  margin-right: auto;
  background: #000;
}
.box_file_name {
  padding: 10px 65px;
}
.file_name {
  font-size: 14px;
}
.text_center {
  text-align: center;
}
.title_modal {
  font-size: 18px;
}
.box_input_modal {
  padding: 20px 0px;
  width: 55%;
}
.box_upload {
  width: 100%;
}
.upload_file {
  /* border: 1px solid; */
  width: 100%;
  text-align: center;
  /* height: 399px;
  margin-left: auto;
  margin-right: auto; */
}
.pdf_page > canvas {
  margin-left: auto;
  margin-right: auto;
}
span.upload_ant_custom > div {
  width: 547px !important;
  height: 397px !important;
}
ul.breadcrumb {
  list-style-type: none;
  display: flex;
}
.item_breadcrumb {
  margin-right: 10px;
}
.link_breadcrumb {
  font-size: 12px;
  color: #000;
}
.space_datepicker {
  width: 100%;
}
.display_none {
  display: none !important;
}
.display_flex {
  display: flex;
}
.display_block {
  display: block;
}
.box_type_image {
  position: absolute;
  width: 60px;
  height: 23px;
  border: 1px solid #707070;
  border-radius: 5px;
  padding: 3px 0px;
  background: #ffff;
  text-align: center;
  margin-top: 5px;
  margin-left: 5px;
}
.title_type_image {
  font-weight: 500;
}
.click_edit_image {
  width: 100%;
  max-height: 210px;
  display: flex;
  height: 210px;
}
.click_edit_image {
  background: #e3e3e3;
}
.click_edit_image,
.click_edit_image:hover {
  color: #000;
  border: none;
}
.file_viewer:hover .click_edit_image:hover img.img_car_card_hover {
  opacity: 0.5;
}
.click_edit_image:hover .box_title_edit,
.click_edit_image:hover .box_title_upload_viewer,
.file_viewer:hover .box_title_edit {
  opacity: 1;
  z-index: 999;
}
.click_edit_image:hover div.box_type_image {
  background: #707070;
  border: 1px solid #707070;
}
.click_edit_image:hover div.file_viewer {
  background: #707070;
}

.box_title_edit {
  position: absolute;
  height: 200px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 385px;
  opacity: 0;
}
.box_title_upload_viewer {
  display: none;
  position: absolute;
  height: 437px;
  align-items: center;
  justify-content: center;
  display: flex;
  width: 310px;
  opacity: 0;
}
span.title_edit_image {
  display: block;
  width: 100px;
  height: 30px;
  background: #15499b;
  border-radius: 20px;
  text-align: center;
  padding: 5px;
  font-size: 14px;
  color: #ffff;
}
img.preview_box {
  max-height: 399px;
  width: 100%;
}
.upload_delete_img {
  display: none;
}
.box_upload:hover {
  background: #00000080;
  opacity: 0.5;
}
.box_upload:hover .upload_delete_img {
  display: block;
  opacity: 1;
  position: absolute;
  left: 50%;
  top: 50%;
  right: 50%;
  color: red;
  font-size: 25px;
  background: none;
  border: none;
  z-index: 9999;
}
.box_title_page {
  display: flex;
}
.title_box_documents_uploaded {
  width: 50%;
}
.btn_documents_uploaded {
  width: 50%;
  text-align: right;
}
.image_gallery_slide > img {
  max-height: 435px !important;
  min-height: 435px;
}
.iframe_pdf {
  width: 100%;
  height: 100%;
  border: none;
}

.iframe_pdf_upload {
  height: 420px;
}
.boxcontenfile {
  width: 100%;
}
.box_file_viewer {
  /* height: 533px; */
  padding: 10px 50px;
}
.box_file_viewer_img {
  width: 33%;
}
.box_file_viewer_doc {
  width: 100%;
}
.file_viewer {
  background: #e3e3e3;
  height: 437px;
  justify-content: center;
  justify-items: center;
  align-items: center;
  display: flex;
}
.box_title_file {
  display: flex;
  width: 100%;
}
.box_img_document {
  width: 75%;
}
.box_doc_document {
  width: 25%;
}
.title_text_viewer {
  font-size: 25px;
}
.img_doc {
  width: 100%;
  max-height: 437px;
}
.mp_20 {
  margin-top: 20px;
}
.disabled {
  opacity: 0.5;
}
span.btn_image_preview {
  background: #888888;
  border: 1px solid #d4d4d4;
  margin-right: 20px;
  box-shadow: 0 0px 8px 0 #888888;
}
.mr_0 {
  margin-right: 0px;
}
.justify_center {
  justify-content: center;
}
.box_btn_content_end {
  width: 100%;
  display: flex;
  justify-content: end;
}
.mp_35 {
  margin-top: 35px;
}
.box_btn_under {
  margin-top: 35px;
}
.gutter_row {
  padding: 10px 0px;
}
.mp_btn_create {
  margin-top: 30px;
}

@media (max-width: 1440px) {
  .box_title_file {
    display: block;
  }
  .box_img_document {
    width: 100%;
  }
  .box_doc_document {
    width: 100%;
  }
  .display_flex {
    justify-content: center;
  }
  .titel_text_conten,
  .des_conten {
    font-size: 14px;
  }
  .first_col {
    width: 79%;
    max-width: 79%;
  }
  img.img_car_card {
    min-height: 150px;
  }
  .boxcardconten.insurance_start {
    height: 450px;
    overflow: hidden;
  }
  .card_border.insurance_start {
    height: 400px;
    overflow: hidden;
  }
  .boxcardconten {
    width: 100%;
  }
  .view_document {
    width: auto;
    height: 100%;
  }
  .box_title_edit {
    width: 270px;
  }
  .box_title_edit.document_box {
    width: 282px;
    height: 437px;
  }
  .box_file_viewer {
    height: auto;
  }
  /* .file_viewer { */
  /* minheight: 290px; */
  /* } */
  .box_title_upload_viewer {
    height: 290px;
    width: 190px;
  }
  .title_modal {
    font-size: 14px;
  }
  .mp_btn_create {
    margin-top: 5px;
  }
  .btn {
    font-size: 14px;
    width: 170px;
  }
}
@media (max-width: 1180px) {
  .box_title_file {
    display: block;
  }
  .box_img_document,
  .box_doc_document {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .box_title_file {
    display: block;
  }
  .box_img_document,
  .box_doc_document {
    width: 100%;
  }
  img.img_car_card {
    min-height: unset;
    width: 100%;
    margin: auto;
  }
  .box_btn_under {
    /* margin-top: 70px; */
  }
  .box_title_edit {
    height: 209px;
    width: 162px;
  }
  .display_flex {
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .boxcardconten.height_center {
    height: 560px;
  }
  .card_border.height_center {
    height: 510px;
  }
  .box_title_edit {
    height: 209px;
    width: 125px;
  }
  span.title_edit_image {
    width: 70px;
  }
  .box_doc_document {
    margin-top: 100px;
  }
  .box_file_viewer_img {
    width: 100%;
  }
  .box_file_viewer {
    height: auto;
  }
  .file_viewer {
    height: auto;
  }
}

@media (max-width: 768px) {
  .boxcardconten.height_start {
    height: 480px;
  }
  .card_border.height_start {
    height: 440px;
  }
  .btn {
    width: 170px;
    font-size: 14px;
  }
  .boxcardconten.height_center {
    height: 680px;
  }
  .card_border.height_center {
    height: 645px;
  }
}

@media (max-width: 576px) {
  .box_card_list_cars {
    flex-flow: row wrap;
  }
  .card_car {
    width: 50%;
    margin-bottom: 20px;
  }
  img.img_car_card {
    min-height: unset;
    width: 100%;
    margin: auto;
  }
  .box_title_edit {
    width: 45%;
  }
  .boxcardconten.height_start {
    height: 600px;
  }
  .card_border.height_start {
    height: 565px;
  }
  .btn {
    font-size: 12px;
    width: 150px;
  }
  .text_status {
    font-size: 14px;
  }
  .box_status {
    width: 120px;
    height: 22px;
  }
  .boxcardconten.insurance_start {
    height: 600px;
  }
  .card_border.insurance_start {
    height: 565px;
  }
}
