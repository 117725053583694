// CONSTANTS
$vehicleWidth: 300px;
$modelWidth: 150px;
$priceWidth: 200px;
$stationWidth: 300px;
$statusWidth: 100px;
$serviceWidth: 100px;
$utilizationWidth: 100px;
$caseWidth: 1000px;

$vehicleAndModelWidth: calc(#{$vehicleWidth} + #{$modelWidth});
$vehicleAndModelAndPriceWidth: calc(#{$vehicleAndModelWidth} + #{$priceWidth});
$vehicleAndModelAndPriceAndStationWidth: calc(#{$vehicleAndModelAndPriceWidth} + #{$stationWidth});
$vehicleAndModelAndPriceAndStationAndStatusWidth: calc(
  #{$vehicleAndModelAndPriceAndStationWidth} + #{$statusWidth}
);
$vehicleAndModelAndPriceAndStationAndStatusAndServiceWidth: calc(
  #{$vehicleAndModelAndPriceAndStationAndStatusWidth} + #{$serviceWidth}
);
$vehicleAndModelAndPriceAndStationAndStatusAndServiceAndUtilizationWidth: calc(
  #{$vehicleAndModelAndPriceAndStationAndStatusAndServiceWidth} + #{$utilizationWidth}
);
$allCaseWidth: calc(#{$caseWidth} * 3);

$totalWidth: calc(
  #{$vehicleAndModelAndPriceAndStationAndStatusAndServiceAndUtilizationWidth} + #{$allCaseWidth}
);

$columnGap: 0.25rem;
$totalGap: calc(#{$columnGap} * 9);
$totalWidthWithGap: calc(#{$totalWidth} + #{$totalGap});

$heightHeader: 50px;
$vehicleRowHeight: 40px;

$haupColor: rgb(21, 73, 155);
$colorSortUp: $haupColor;
$colorSortDown: rgb(115, 14, 14);

// MIXINS
@mixin vehicleReportRow($height) {
  width: $totalWidth;
  gap: $columnGap;
  position: relative;
  display: grid;
  height: $height;
  grid-template-columns: $vehicleWidth $modelWidth $priceWidth $stationWidth $statusWidth $serviceWidth $utilizationWidth $caseWidth $caseWidth $caseWidth;
}

@mixin buttonReportHeader() {
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
  display: flex;
  border: none;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: $haupColor;
}

// GLOBAL CLASSES
.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.overflow-x-hidden {
  overflow-y: auto;
  overflow-x: hidden;
}

.no-overflow {
  overflow: hidden;
}

.filter-report-main {
  color: white;
  margin-left: 0.25rem;
}

.hidden {
  display: hidden;
}

.filter-report-up {
  color: $colorSortUp;
  margin-left: 0.25rem;
}

.filter-report-down {
  color: $colorSortDown;
  margin-left: 0.25rem;
}

// REPORT CANVAS
#vehicle-report-canvas {
  height: 100%;
  width: 100%;
  padding-left: 0;
  overflow: hidden;
  display: grid;
  position: relative;
  .vehicle-col-container {
    opacity: 0;
    transition: visibility 0.2s ease-in-out 0s, opacity 0.4s ease-in-out 0.2s;
    position: absolute;
    visibility: hidden;
    &.active {
      opacity: 1;
      visibility: visible;
    }
    width: $vehicleWidth;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    .vehicle-col-container__wrapper {
      background-color: white;
      position: relative;
      height: inherit;
      width: 100%;
      overflow: hidden;
      box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.2);
      .vehicle-col-container__header {
        height: $heightHeader * 2;
        width: 100%;
        display: grid;
        .vehicle-col-container__header-title {
          @include buttonReportHeader();
          height: 50px;
        }
      }
      .vehicle-col-container__list-wrapper {
        position: relative;
        height: inherit;
        overflow: hidden;
        ul.vehicle-col-container__list {
          position: absolute;
          width: $vehicleWidth;
          li.vehicle-col-container__list-item {
            background-color: rgba(128, 128, 128, 0.02);
            &:nth-child(2n) {
              background-color: rgba(128, 128, 128, 0.1);
            }
            height: $vehicleRowHeight;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            text-align: center;
            font-size: 0.8rem;
            width: 100%;
            font-weight: 600;
            color: black;
          }
        }
      }
    }
  }
  > .slider {
    overflow: auto;
    position: relative;
    width: inherit;
    .fixed-container {
      position: sticky;
      height: calc(#{$heightHeader} * 2);
      z-index: 20;
      top: 0%;
      background-color: white;
      width: $totalWidthWithGap;
    }
    .loading-report {
      position: absolute;
      top: 50%;
      left: 50%;
    }
  }
}

// HEADER

ul.table-header {
  @include vehicleReportRow($heightHeader);

  > button {
    @include buttonReportHeader();
    transition: background-color 0.4s ease-in-out 0s, color 0.4s ease-in-out 0s;

    &:hover {
      cursor: pointer;
      background-color: #111141;
      color: rgb(255, 226, 199);
    }

    span[role='img'] {
      display: hidden;
    }
  }

  button[data-sortable='false'] {
    &:hover {
      background-color: $haupColor;
      pointer-events: none;
      color: white;
    }
    .filter-icons {
      display: none;
    }
  }

  button[data-sort='null'] {
    .filter-up-down {
      display: none;
    }
  }

  button[data-sort='desc'] {
    box-shadow: inset 0 0 10px 5px rgba(115, 14, 14, 0.2);
    background-color: #f5c5c4;
    color: rgb(115, 14, 14);
    &:hover {
      background-color: #f5c5c4;
      color: rgb(115, 14, 14);
      filter: brightness(1.05);
    }
    .filter-up-down,
    .filter-report-main {
      display: none;
    }
    .filter-report-down {
      display: block;
    }
  }
  button[data-sort='asc'] {
    box-shadow: inset 0 0 10px 5px rgba(21, 73, 155, 0.2);
    background-color: #dbdbff;
    color: $colorSortUp;
    &:hover {
      background-color: #dbdbff;
      color: $colorSortUp;
      filter: brightness(1.05);
    }
    .filter-up-down,
    .filter-report-main {
      display: none;
    }
    .filter-report-up {
      display: block;
    }
  }
}

div.light {
  background-color: rgba(128, 128, 128, 0.02);
}
div.gray {
  background-color: rgba(128, 128, 128, 0.1);
}


// ROWS
ul.vehicle-row, ul.element-row {
  display: grid;
  gap: 0;
  width: $totalWidthWithGap;
  max-height: calc(100% - 7.5rem);
  position: relative;

  .row-wrapper {
    position: relative;
  }

  div.light {
    background-color: rgba(128, 128, 128, 0.02);
  }
  div.gray {
    background-color: rgba(128, 128, 128, 0.1);
  }

  .blurry {
    filter: blur(5px);
  }

  div {
    @include vehicleReportRow(40px);
    li {
      display: flex;
      height: $vehicleRowHeight;
      justify-content: center;
      align-items: center;
      position: relative;
      text-align: center;
      font-size: 0.8rem;
      width: 100%;
      font-weight: 600;
      color: black;
    }
    li:nth-child(2n) {
      color: $haupColor;
    }
  }
}

// CUBE REPORT
.report-cube,
.report-cube-header {
  pointer-events: none;
  width: 40px;
  height: 40px;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;
  transition: background-color 0.1s ease-in-out, opacity 0.1s ease-in-out;
  color: black;
  background-color: white;
  transform-origin: center center;
  border: 0.05rem solid rgba(0, 0, 0, 0.2);
}

  .report-cube {
    background-color: rgba(21, 200, 155, 0.5);
  }

.report-cube[data-type='passed'] {
  background-color: rgba(2, 30, 122, 0.1);
}

.report-cube[data-type='booking'] {
  cursor: pointer;
  pointer-events: visible;
  background-color: rgba(210, 29, 0, 0.5);
}

.report-cube[data-type='available'] {
  cursor: pointer;
  pointer-events: visible;
  position: relative;
  width: 100%;
  height: 100%;
  transition: background-color 0.1s ease-in-out 0s;

  &:hover {
    background: radial-gradient(circle at 50% 50%, $haupColor, transparent);
    &:before {
      opacity: 1;
    }
  }
  &:before {
    content: '+';
    left: 25%;
    transition: opacity 0.1s ease-in-out;
    opacity: 0;
    width: 100%;
    bottom: 0;
    color: white;
    height: 40px;
    font-size: 30px;
    position: absolute;
  }
}

.report-cube[data-type='available'].selected {
  background-color: green;
  transition: none;
  &:hover {
    background-color: green;
    &:before {
      content: '';
      top: 0;
      width: inherit;
      height: inherit;
      background-color: green;
    }
  }
}

.report-cube[data-type='half-available'] {
  pointer-events: visible;
  position: relative;
  width: 100%;
  height: 100%;
  &:before {
    content: '';
    width: 50%;
    transform: translateX(-50%);
    height: inherit;
    background-color: rgba(0, 255, 0, 0.2);
    border-right: 1px solid gray ;
  }
  &:after {
    content: '+';
    width: 50%;
    bottom: 0;
    display: flex;
    color: $haupColor;
    justify-content: center;
    align-items: center;
    transform: translateX(50%);
    height: inherit;
    cursor: pointer;
    position: absolute;
    background-color: rgba(71, 88, 245, 0.05);
  }
}

.report-cube[data-type='ma'] {
  background-color: rgba(231, 30, 16, 0.2);
}

.report-cube.selected {
  background-color: green;
  transition: none;
  &:hover {
    background-color: inherit;
  }
  &:before {
    opacity: 0;
  }
}

@keyframes pulse {
  from {
    opacity: 0.8;
  }
  50% {
    opacity: 0.5;
  }
  to {
    opacity: 0.8;
  }
}

.report-cube.hovered {
  width: 100%;
  height: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    background-color: $haupColor;
    opacity: 0.4;
    height: inherit;
    width: inherit;
  }
}

.report-cube.unvalid {
  width: 100%;
  height: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    background-color: red;
    opacity: 0.4;
    height: inherit;
    width: inherit;
  }
}

.report-cube.highlight {
  width: 100%;
  height: 100%;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    background-color: orange;
    opacity: 0.2;
    height: inherit;
    width: inherit;
  }
}

.helpers-vehicle-report.active {
  visibility: visible;
  opacity: 1;
}

// HELPERS BUTTONS
.helpers-vehicle-report {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 200px;
  height: 100px;
  transition: opacity 0.5s ease-in-out 0s;
  button {
    border: none;
    background-color: #111141;
    border-radius: 999px;
    width: 60px;
    height: 60px;
    display: grid;
    cursor: pointer;
    svg {
      place-self: center;
      width: 50%;
      height: 50%;
      fill: white;
    }
    &:first-child {
      filter: brightness(1.02);
      &:hover {
        svg:first-child {
          animation: goUp 1s ease-in-out 0s infinite;
        }
      }
    }
  }

}

@keyframes goUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
}